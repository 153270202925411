import { Fragment, useEffect, useRef, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import queryString from 'query-string';
import { ReactComponent as Chevron } from 'assets/icons/arrowShort.svg';
import { ReactComponent as Pdf } from 'assets/icons/files/pdf.svg';
import { ReactComponent as Csv } from 'assets/icons/files/csv.svg';
import { ReactComponent as Doc } from 'assets/icons/files/doc.svg';
import { ReactComponent as Docx } from 'assets/icons/files/docx.svg';
import { ReactComponent as Image } from 'assets/icons/files/image.svg';
import { ReactComponent as Ppt } from 'assets/icons/files/ppt.svg';
import { ReactComponent as Pptx } from 'assets/icons/files/ppt.svg';
import { ReactComponent as Txt } from 'assets/icons/files/txt.svg';
import { ReactComponent as Xls } from 'assets/icons/files/xls.svg';
import { ReactComponent as Xlsx } from 'assets/icons/files/xlsx.svg';
import { ReactComponent as General } from 'assets/icons/files/general.svg';
import NoFiles from 'assets/images/no-documents.svg';
import EmptyState from 'components/emptyState/EmptyState';
import Divider from 'components/divider/Divider';
import DownloadFileButton from 'components/buttons/DownloadFileButton';
import { IResources } from '../components/Resources';
import {
  COURSE_RELATIONSHIP,
  ICourse,
  IVideoAllFiles,
  IVideoFile
} from 'query/course-module/dto';
import classes from './Documents.module.scss';

export const renderFileIcon = (fileName: string) => {
  const words = fileName.split('.');
  const extension = words[words.length - 1];

  let Icon;
  switch (extension) {
    case 'pdf':
      Icon = <Pdf />;
      break;
    case 'csv':
      Icon = <Csv />;
      break;
    case 'doc':
      Icon = <Doc />;
      break;
    case 'docx':
      Icon = <Docx />;
      break;
    case 'ppt':
      Icon = <Ppt />;
      break;
    case 'pptx':
      Icon = <Pptx />;
      break;
    case 'txt':
      Icon = <Txt />;
      break;
    case 'Xls':
      Icon = <Xls />;
      break;
    case 'xlsx':
      Icon = <Xlsx />;
      break;
    case 'jpg':
      Icon = <Image />;
      break;
    case 'jpeg':
      Icon = <Image />;
      break;
    case 'png':
      Icon = <Image />;
      break;
    case 'avif':
      Icon = <Image />;
      break;
    case 'webp':
      Icon = <Image />;
      break;
    default:
      Icon = <General />;
      break;
  }

  return Icon;
};

interface ISingleDoc {
  file: IVideoFile;
  isAdminPage: boolean;
  videoTitle: string;
  course: ICourse;
}

const SingleDoc = ({ videoTitle, file, isAdminPage, course }: ISingleDoc) => {
  const isRestricted = !(
    isAdminPage ||
    course?.free_course ||
    (course?.purchased?.active &&
      course.course_relationship === COURSE_RELATIONSHIP.PURCHASED) ||
    course.course_relationship === COURSE_RELATIONSHIP.CREATOR
  );

  return (
    <div className={classes['documents__item']}>
      <div className={classes['documents__item__heading']}>
        <div className={classes['documents__item__heading__title']}>
          {renderFileIcon(file.name)} {file.name}
        </div>
      </div>
      <DownloadFileButton
        file={file}
        isAdminPage={isAdminPage}
        isDisabled={isRestricted}
        isFreeCourse={!!course.free_course}
      />
    </div>
  );
};

interface IVideoWrapperProps {
  video: IVideoAllFiles;
  isAdminPage: boolean;
  course: ICourse;
}
const VideoWrapper = ({ video, isAdminPage, course }: IVideoWrapperProps) => {
  const { videoId } = useParams();
  const { state } = useLocation();
  const ref = useRef<any>(video.video_id);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (state?.autoFocus && video.video_id === videoId) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.autoFocus]);

  return (
    <div
      ref={ref}
      className={classes['documents__video__wrapper']}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={classes['documents__video']}>
        <div className={classes['documents__video__heading']}>
          <div className={classes['documents__video__heading__title']}>
            <h5 className={classes['u-bold']}>{video.video_title}</h5>
          </div>
          {!!video.files?.length && (
            <div className={classes['u-text--content']}>
              {video.files.length} Attached Documents
            </div>
          )}
          {!video.files?.length && (
            <div className={classes['u-text--content']}>
              No Attached Documents
            </div>
          )}
        </div>
        <Chevron className={`${!isOpen ? classes['closed'] : ''}`} />
      </div>
      {isOpen && (
        <>
          {!!video.files?.length &&
            video.files.map((file: IVideoFile, i: number) => (
              <Fragment key={file._id}>
                {i === 0 && <Divider />}
                <SingleDoc
                  key={file._id}
                  file={file}
                  isAdminPage={isAdminPage}
                  videoTitle={video.video_title}
                  course={course}
                />
              </Fragment>
            ))}
        </>
      )}
    </div>
  );
};

interface IDocumentsProps {
  resources: IVideoAllFiles[];
  courseProps: IResources['courseProps'];
  showEmptyStateBtn: boolean;
}

const Documents = ({
  resources,
  courseProps,
  showEmptyStateBtn
}: IDocumentsProps) => {
  const { slug, videoId } = useParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);

  const { isAdminPage, isVideoPage, course } = courseProps;

  const renderFiles = () => {
    const filteredResources = resources.filter((res) => !!res.files?.length);
    let preparedVideos = [];

    if (isVideoPage)
      preparedVideos = filteredResources.filter(
        (res) => res.video_id === videoId
      );
    else preparedVideos = filteredResources;

    if (!!preparedVideos.length) {
      return preparedVideos.map((file) => (
        <VideoWrapper
          key={file.video_id}
          video={file}
          isAdminPage={isAdminPage}
          course={course}
        />
      ));
    } else {
      return (
        <EmptyState
          title={`This ${
            isVideoPage ? 'video' : 'course'
          } does not have any files attached to it.`}
          message={
            course.course_relationship === COURSE_RELATIONSHIP.CREATOR
              ? 'You can upload videos and attach files to them in the Videos tab.'
              : ''
          }
          buttonName={showEmptyStateBtn ? 'Go to Videos' : ''}
          onClick={() =>
            navigate({
              pathname: `/courses/${slug}`,
              search: `${createSearchParams({ changes: changes as string })}`
            })
          }
          icon={NoFiles}
        />
      );
    }
  };

  return (
    <div className={classes['documents']}>
      <div className={classes['documents__wrapper']}>{renderFiles()}</div>
    </div>
  );
};

export default Documents;
