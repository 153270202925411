import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/logo.png';
import { ReactComponent as LogoWhite } from 'assets/images/logo-light.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as LinkedIn } from 'assets/icons/linkedin.svg';
import { subscribeToNewsletterMutationQuery } from 'query/acc-module/mutations';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import { windowContext } from 'context/windowsContext';
import { appContext } from 'context/appContext';
import { toastContext } from 'context/toastContext';
import { tokenExpired } from 'utils/storage';
import classes from './Footer.module.scss';

const links = [
  {
    name: 'Explore Courses',
    url: '/courses'
  },
  {
    name: 'How to use',
    url: '/how-to'
  },
  {
    name: 'Creator Onboarding',
    url: '/creator-onboarding'
  },
  {
    name: 'My profile',
    url: '/user',
    auth: true
  },
  {
    name: 'Blog',
    url: '/blog'
  }
];

const Footer = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { theme } = useContext(appContext);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;
  const { setToast } = useContext(toastContext);
  const hasExpired = tokenExpired();

  const { isLoading, mutate: subscribeHandler } = useMutation({
    mutationKey: ['subscribe-to-newsletter'],
    mutationFn: async () =>
      await subscribeToNewsletterMutationQuery(email).mutationFn(),
    onSuccess: () => {
      setEmail('');
      setToast({
        type: 'success',
        position: 'top',
        autoClose: true,
        msg: 'Verification email sent!'
      });
    },
    onError: async (err: any) => {
      setToast({
        type: 'error',
        position: 'top',
        autoClose: true,
        msg: err.message || '',
        title: 'Subscription Failed'
      });
    }
  });

  return (
    <div className={classes['wrapper']}>
      <div className={classes['divider-wrapper']}>
        <Divider />
      </div>
      <div className={classes['footer__top']}>
        <div className={classes['footer__top__col-left']}>
          <div className={classes['logo']}>
            {theme === 'dark' ? (
              <LogoWhite />
            ) : (
              <img src={Logo} alt="opus-nft-logo" />
            )}
            {isLgMobile && (
              <div className={classes['footer__bottom__socials']}>
                <Button
                  variant="link-neutral"
                  isIconBtn
                  icon={Twitter}
                  onClick={() =>
                    window.open('https://x.com/OpusEduPlatform', '_blank')
                  }
                />
                <Button
                  variant="link-neutral"
                  isIconBtn
                  icon={LinkedIn}
                  onClick={() =>
                    window.open(
                      'https://www.linkedin.com/company/105311929',
                      '_blank'
                    )
                  }
                />
              </div>
            )}
          </div>
          <div className={classes['footer__top__col-left__links']}>
            {links.map((link, i) => {
              if ((!!link?.auth && !hasExpired) || !link?.auth) {
                return (
                  <div
                    key={i}
                    className={classes['link']}
                    onClick={() => navigate(link.url)}
                  >
                    {link.name}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
        {/* <div className={classes['footer__top__col-right']}>
          <div
            className={`${classes['u-bold']} ${classes['u-body1']} ${classes['u-m16-bot']}`}
          >
            Subscribe
          </div>
          <div className={classes['u-m24-bot']}>
            Join our newsletter to stay up to date on features and releases.
          </div>
          <div className={classes['form']}>
            <InputWrapper
              className={classes['form__input']}
              placeholder="Enter your email"
              onChange={(input: string) => setEmail(input)}
              value={email}
            />
            <Button
              minWidth="md"
              isLoading={isLoading}
              onClick={() => subscribeHandler()}
            >
              Subscribe
            </Button>
          </div>
          <div
            className={`${classes['u-text--content']} ${classes['u-body3']}`}
          >
            By subscribing you agree to with our{' '}
            <Link to="/privacy" target="_blank">
              Privacy Policy
            </Link>{' '}
            and provide consent to receive updates from our company.
          </div>
          <div className={classes['contact']}>
            Contact us:{' '}
            <a href="mailto: support@opusedu.io">support@opusedu.io</a>
          </div>
        </div> */}
      </div>
      <Divider />
      <div className={classes['footer__bottom']}>
        <div className={classes['footer__bottom__terms-and-conditions']}>
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
          <Link to="/terms-and-conditions" target="_blank">
            Terms of Service
          </Link>
          <Link to="/cookies-policy" target="_blank">
            Cookies Settings
          </Link>
        </div>
        {!isLgMobile && (
          <div className={classes['footer__bottom__socials']}>
            <Button
              variant="link-neutral"
              isIconBtn
              icon={Twitter}
              onClick={() =>
                window.open('https://x.com/OpusEduPlatform', '_blank')
              }
            />
            <Button
              variant="link-neutral"
              isIconBtn
              icon={LinkedIn}
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/company/105311929',
                  '_blank'
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
