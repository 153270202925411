import { useContext } from 'react';
import { Rating, ThinRoundedStar } from '@smastrom/react-rating';
import { appContext } from 'context/appContext';
import { ICourse } from 'query/course-module/dto';
import { colors } from 'utils/styles';
import { formatPrice } from 'utils/format';
import classes from './Rating.module.scss';
import '@smastrom/react-rating/style.css';

interface IRating {
  rating: number;
  count: ICourse['ratings'];
  setRating?: (rating: number) => void;
  readOnly?: boolean;
  onlyStars?: boolean;
  withCountPerRating?: boolean;
  isYellow?: boolean;
  totalGivenReviews?: number;
  onClick?: () => void;
}

const RatingComponent = ({
  rating,
  count,
  setRating,
  readOnly,
  onlyStars,
  withCountPerRating,
  isYellow,
  totalGivenReviews,
  onClick
}: IRating) => {
  const { theme } = useContext(appContext);

  const totalReviews = totalGivenReviews
    ? totalGivenReviews
    : Object.values(count).reduce(
        (accumulator, value) => accumulator + value,
        0
      );

  const itemStyles = {
    itemShapes: ThinRoundedStar,
    itemStrokeWidth: 2,
    activeFillColor: isYellow
      ? colors.warning30
      : theme === 'dark'
      ? '#ffffff'
      : '#050505',
    activeStrokeColor: isYellow
      ? colors.warning30
      : theme === 'dark'
      ? '#ffffff'
      : '#050505',
    inactiveFillColor: 'transparent',
    inactiveStrokeColor: isYellow
      ? theme === 'dark'
        ? colors.black70
        : colors.white50
      : theme === 'dark'
      ? '#ffffff'
      : '#050505'
  };

  return (
    <div
      className={`${classes['wrapper']} ${
        !!onClick ? classes['u-cursor--pointer'] : ''
      }`}
      onClick={() => !!onClick && onClick()}
    >
      {!onlyStars && (
        <span className={classes['rating__value']}>{rating.toFixed(1)}</span>
      )}
      <Rating
        style={{ maxWidth: 120 }}
        value={rating}
        onChange={setRating}
        readOnly={readOnly}
        itemStyles={itemStyles}
      />

      {withCountPerRating && (
        <span className={classes['rating__count-per-rating']}>
          {/*// @ts-ignore */}
          <>({count[rating]})</>
        </span>
      )}
      {!onlyStars && (
        <span className={classes['rating__total-count']}>{`(${formatPrice(
          totalReviews
        )})`}</span>
      )}
    </div>
  );
};

RatingComponent.defaultProps = {
  value: 0,
  count: 0,
  readOnly: false
};

export default RatingComponent;
