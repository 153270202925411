import { useCallback, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';
import { appContext } from 'context/appContext';
import { userContext } from 'context/userContext';
import { windowContext } from 'context/windowsContext';
import Button from 'components/buttons/Button';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import Player from 'components/player/Player';
import SEO from 'components/SEO/SEO';
import VideoList from '../components/VideoList';
import {
  activatedCourseVideoDataQuery,
  adminCourseVideoDataQuery,
  courseVideoDataQuery
} from 'query/course-module/queries';
import { videoProgressMutation } from 'query/course-module/mutations';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  ICourse,
  ISingleCourseVideo,
  IVideo
} from 'query/course-module/dto';
import { colors } from 'utils/styles';
import { formatNames } from 'utils/format';
import classes from '../SingleCourse.module.scss';

interface IVideoViewProps {
  courseData: ICourse;
  view: 'video' | 'description' | 'video-and-description' | 'video-list';
  videosListData?: IVideo[];
}

const VideoView = ({ courseData, view, videosListData }: IVideoViewProps) => {
  const { videoId, slug } = useParams();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const navigate = useNavigate();
  const { isTheatreMode, setIsTheatreMode } = useContext(appContext);
  const { userData } = useContext(userContext);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;
  const isVideoPage = !!videoId;
  const queryClient = useQueryClient();

  const { _id, course_relationship, purchased } = courseData;

  // Get video
  const {
    isLoading: videoIsLoading,
    error: videoError,
    data: videoData
  } = useQuery<boolean, Error, ISingleCourseVideo>({
    ...(userData?.isAdmin
      ? adminCourseVideoDataQuery(
          _id as string,
          videoId as string,
          `?&changes=${changes}`
        )
      : course_relationship === COURSE_RELATIONSHIP.CREATOR
      ? courseVideoDataQuery(
          _id as string,
          videoId as string,
          `?changes=${changes}`
        )
      : activatedCourseVideoDataQuery(_id as string, videoId as string)),
    enabled: isVideoPage && !!_id,
    onError: (error) => {
      navigate(`/courses/${slug}/videos?changes=${COURSE_CHANGES.NO_CHANGES}`);
    }
  });

  // Send video progress
  const { mutate: onProgressMutation } = useMutation(
    videoProgressMutation(_id, videoId as string)
  );

  const isExamFinished = useCallback(() => {
    if (!courseData?.purchased) return false;
    return Object.keys(courseData.purchased.tests_completed).some(
      (key) => key === videoId
    );
  }, [courseData, videoId]);

  const handleOnProgress = (data: {
    playedSeconds: number;
    is_finished: boolean;
  }) => {
    // Send video progress for users who have purchased the course
    if (
      course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
      purchased?.active
    ) {
      onProgressMutation(data);
    }

    // If the last watched video is not the current video,
    // invalidate the me-data and last watched course queries
    // in userContext, thus updating the userData object
    if (userData.last_watched_video !== videoId) {
      queryClient.invalidateQueries({
        queryKey: ['me-data']
      });
      queryClient.invalidateQueries({
        queryKey: ['courses-data', { id: courseData._id }]
      });
    }

    // If the video is NOT STARTED, update to STARTED
    if (!purchased?.videos_checkpoint[videoId as string]) {
      queryClient.invalidateQueries([
        'courses-data',
        { id: slug, changes: changes }
      ]);
    }
  };

  // Remove theatre mode on mobile devices
  useEffect(() => {
    if (isLgMobile && isTheatreMode) {
      setIsTheatreMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLgMobile, isTheatreMode]);

  if (videoIsLoading) return <Loader />;
  if (videoError) return <Error error={videoError} />;

  const {
    description: videoDescription,
    title: videoTitle,
    exam,
    file_count
  } = videoData || {};

  const nextVideoLink = !!videoData?.next_video_id
    ? `/courses/${slug}/${videoData.next_video_id}/videos?changes=${changes}`
    : '';

  const isPublic =
    userData && userData._id && userData._id !== ''
      ? courseData.creator_id !== userData._id
      : true;

  return (
    <div
      className={`
        ${classes['main']}  
        ${isTheatreMode ? classes['main--theatre-mode'] : ''}
      `}
    >
      <SEO
        type="product"
        title={videoData.title}
        desc={videoData.description}
        image={videoData.thumbnail}
        creatorName={formatNames(
          courseData.creator.first_name + ' ' + courseData.creator.last_name
        )}
      />
      {(view === 'video' || view === 'video-and-description') && (
        <div
          className={`${classes['main__video']}   
          ${isTheatreMode ? classes['main__video--theatre-mode'] : ''}`}
        >
          <Player
            src={videoData?.path}
            hasControls
            checkpoint={
              +(isVideoPage && purchased?.videos_checkpoint[videoId]) || 0
            }
            onProgress={handleOnProgress}
            nextVideoId={videoData.next_video_id}
            videoData={videoData}
            isExamFinished={isExamFinished()}
          />
        </div>
      )}
      {(view === 'description' || view === 'video-and-description') && (
        <div className={classes['main__content']}>
          <h3 className={classes['u-semiBold']}>
            {videoTitle || '(Untitled Video)'}
          </h3>
          <div className={classes['details']}>
            <div className={classes['details__title']}>Video Description</div>
            <div className={classes['details__content']}>
              <TextWithCharLimit text={videoDescription || ''} limit={300} />
            </div>
          </div>
          {!!file_count && (
            <Link
              className={classes['u-medium']}
              style={{ color: colors.purple50 }}
              to={`/courses/${slug}/${videoId}/documents?changes=${changes}`}
              state={{ autoFocus: true }}
            >
              {file_count} Document{file_count > 1 ? 's' : ''} attached
            </Link>
          )}
          <div>
            {(userData?.isAdmin ||
              course_relationship === COURSE_RELATIONSHIP.CREATOR ||
              (course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
                purchased.active)) &&
              !!exam?.length && (
                <Button
                  onClick={() =>
                    navigate(
                      `/courses/${courseData.slug}/${videoId}/test-questions?changes=${changes}`,
                      { state: { openTest: true, nextVideoLink } }
                    )
                  }
                  minWidth="md"
                >
                  {userData?.isAdmin ||
                  course_relationship === COURSE_RELATIONSHIP.CREATOR
                    ? 'View Test'
                    : !isExamFinished()
                    ? 'Take Test'
                    : 'View Test Results'}
                </Button>
              )}
          </div>
        </div>
      )}
      {view === 'video-list' && !!videosListData && (
        <VideoList
          courseProps={{
            course: courseData,
            isAdminPage: false,
            isPublic,
            isCourseActivated:
              course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
              purchased?.active,
            isVideoPage: !!videoId
          }}
          videos={videosListData}
        />
      )}
    </div>
  );
};

export default VideoView;
