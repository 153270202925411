import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Learn } from 'assets/icons/learning-icon.svg';
import { ReactComponent as Movie } from 'assets/icons/movie-icon.svg';
import { ReactComponent as Wallet } from 'assets/icons/wallet-icon.svg';
import { ReactComponent as Proof } from 'assets/icons/proof-icon.svg';
import EarnMoreKeepMore from 'assets/images/onboarding-creators/earn-more-keep-more.svg';
import FullPricingControl from 'assets/images/onboarding-creators/full-pricing-control.svg';
import ReachGlobalAudience from 'assets/images/onboarding-creators/reach-global-audience.svg';
import CreateCustomizeCourses from 'assets/images/onboarding-creators/create-and-customise.svg';
import BlockchainVerifiedCertificates from 'assets/images/onboarding-creators/nft-certificates.svg';
import StepRow from './components/StepRow';
import Button from 'components/buttons/Button';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import { gtmEventHandler } from 'utils/google-tag-manager';
import classes from './HowToPage.module.scss';

const CreatorOnboarding = () => {
  const navigate = useNavigate();
  const [selectedStep, setSelectedStep] = useState(0);

  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Getting Started', link: '/how-to' }
  ];

  const steps = [
    {
      step: 'Earn More - Keep More',
      guide: 'There are no hidden fees. You get 90% of your course revenue.',
      image: EarnMoreKeepMore
    },
    {
      step: 'Full Pricing control & Secure Payments',
      guide:
        'Set your own course prices and get paid fast with secure, transparent blockchain transactions - ensuring instant earnings.',
      image: FullPricingControl
    },
    {
      step: 'Reach a Global Audience',
      guide:
        'Your courses are available worldwide to learners in multiple languages.',
      image: ReachGlobalAudience
    },
    {
      step: 'Create and Customize Your Courses',
      guide:
        'Boost engagement with quizzes, increase value with course bundles, and drive enrollments with time-limited access',
      image: CreateCustomizeCourses
    },
    {
      step: 'Blockchain verified certificates',
      guide:
        'Increases the value and trustworthiness of your courses with immutable NFT certificates.',
      image: BlockchainVerifiedCertificates
    }
  ];

  return (
    <div className={classes['how-to-wrapper']}>
      <CustomBreadcrumbs crumbs={crumbs} />
      <div className={`${classes['header']} ${classes['header-items']}`}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          Getting Started
        </h5>
        <h2 className={classes['u-semiBold']}>Getting Started With OpusEdu </h2>
      </div>
      <div className={`${classes['header-items']} ${classes['features']}`}>
        <h3 className={classes['u-semiBold']}>
          Teach. Earn. Grow. Join OpusEdu today!
        </h3>
        <div className={classes['cards']}>
          <div className={classes['card']}>
            <Learn />
            <h5 className={`${classes['u-semiBold']}`}>
              Simple learning and teaching
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Create courses, quizzes, and bundles effortlessly - OpusEdu makes
              teaching easy, so you can focus on what you do best.
            </h5>
          </div>
          <div className={classes['card']}>
            <Proof />
            <h5 className={`${classes['u-semiBold']}`}>
              Immutable proof of achievement
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Issue blockchain-verified certificates that are permanent, secure,
              and recognized worldwide.
            </h5>
          </div>
          <div className={classes['card']}>
            <Wallet />
            <h5 className={`${classes['u-semiBold']}`}>
              Fair and Transparent Earnings
            </h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Enjoy a seamless experience with no hidden fees, secure crypto
              payments, and total control over your payments
            </h5>
          </div>
        </div>
      </div>
      <div className={`${classes['header-items']} ${classes['guide']}`}>
        <h3 className={classes['u-semiBold']}>
          Why Join OpusEdu as an Instructor?
        </h3>
        <div className={classes['steps-section']}>
          <div className={classes['steps-wrapper']}>
            {steps.map((step, index) => (
              <StepRow
                step={step}
                index={index}
                selected={selectedStep}
                setSelected={setSelectedStep}
              />
            ))}
          </div>
          <div className={classes['img-wrapper']}>
            <img
              src={steps[selectedStep]?.image || steps[0].image}
              alt="image"
            />
          </div>
        </div>
      </div>
      <div className={`${classes['footer']}`}>
        <div className={`${classes['header-items']}`}>
          <h3 className={`${classes['u-semiBold']}`}>
            Start your journey with OpusEdu
          </h3>
          <h5 className={`${classes['u-text--content']} ${classes['u-body1']}`}>
            Let's build the future of education - together.
          </h5>
        </div>
        <div className={classes['footer-cards']}>
          <div className={classes['card']}>
            <Learn />
            <h5 className={`${classes['u-semiBold']}`}>For learners</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Learn it. Prove it. Own it. Get blockchain-certified credentials
              that validate your true skills.
            </h5>
            <Button
              size="sm"
              variant="neutral"
              className="gtm-button"
              onClick={() => {
                gtmEventHandler('click_start_learning');
                navigate('/courses');
              }}
            >
              Start learning
            </Button>
          </div>
          <div className={classes['card']}>
            <Movie />
            <h5 className={`${classes['u-semiBold']}`}>For creators</h5>
            <h5
              className={`${classes['u-text--content']} ${classes['u-body1']}`}
            >
              Share what you know, earn what you deserve. Teach globally and
              transform your expertise into income on OpusEdu.
            </h5>
            <Button
              size="sm"
              variant="neutral"
              className="gtm-button"
              onClick={() => {
                gtmEventHandler('click_create_course');
                navigate('/courses/create');
              }}
            >
              Create a course
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorOnboarding;
