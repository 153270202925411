import { Column } from 'react-table';
import { tableColumnsWidths } from './utils/table-utils';
import { IAudienceReport } from 'query/course-module/dto';

export const AudienceCols = () => {
  const cols: Column[] = [
    {
      Header: 'Country',
      accessor: 'country',
      Cell: ({ row }: { row: { original: IAudienceReport } }) => (
        <div>{row.original.country}</div>
      )
    },
    {
      Header: 'Total Users',
      accessor: 'total_users',

      Cell: ({ row }: { row: { original: IAudienceReport } }) => (
        <div>{row.original.totalUsers}</div>
      )
    },
    {
      Header: 'New Users',
      accessor: 'new_users',
      Cell: ({ row }: { row: { original: IAudienceReport } }) => (
        <div>{row.original.newUsers}</div>
      )
    }
  ];

  return cols;
};
