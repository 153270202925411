import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import moment from 'moment';
import ThumbnailPlaceholder from 'assets/images/thumbnail-placeholder.png';
import { ReactComponent as checkIcon } from 'assets/icons/check-circle.svg';
import { ReactComponent as copyIcon } from 'assets/icons/copy.svg';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import Divider from 'components/divider/Divider';
import BlogCard from 'components/card/BlogCard';
import Button from 'components/buttons/Button';
import Avatar from 'components/avatar/Avatar';
import Badge from 'components/badge/Badge';
import Loader from 'components/loader/Loader';
import { IBlogData } from 'query/course-module/dto';
import { singleBlogDataQuery } from 'query/course-module/queries';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { formatNames } from 'utils/format';
import { dateFormatUrl } from 'utils/static';
import classes from './SingleBlog.module.scss';

const SingleBlog = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);

  const { isLoading, data } = useQuery<
    boolean,
    Error,
    {
      blog: IBlogData;
      suggestions: IBlogData[];
    }
  >(singleBlogDataQuery(slug as string));

  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Blog', link: '/blog' },
    { name: 'Blog Post', link: `/blog/${data?.blog?._id}` }
  ];

  const { title, topic, tags, date_created, content, creator, image } =
    (data?.blog as IBlogData) || {};

  // DOMPurify sanitizes HTML and prevents XSS attacks.
  const cleanCode = DOMPurify.sanitize(content || '');

  return (
    <div className={classes['single-blog']}>
      <CustomBreadcrumbs crumbs={crumbs} />
      <div className={classes['single-blog__header']}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          {topic}
        </h5>
        <h2 className={`${classes['u-semiBold']} ${classes['title']}`}>
          {title}
        </h2>
        <h5 className={`${classes['u-text--content']}`}>
          {`Published ${moment(date_created).format(dateFormatUrl)}`}
        </h5>
        <div className={classes['badge-wrapper']}>
          {tags?.map((tag) => (
            <Badge key={tag} text={tag} variant="outline" badgeType="info" />
          ))}
        </div>
      </div>
      <div className={classes['single-blog__img-wrapper']}>
        {!!image ? (
          <img alt={title} src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + image} />
        ) : isLoading ? (
          <Loader />
        ) : (
          <img src={ThumbnailPlaceholder} alt={'OpusEDU'} />
        )}
      </div>
      <div className={classes['single-blog__content']}>
        <div
          className={classes['text-wrapper']}
          dangerouslySetInnerHTML={{ __html: cleanCode }}
        />
        <Divider />
        <div className={classes['single-blog__footer']}>
          <div
            className={classes['single-blog__author']}
            onClick={() => navigate(`/user/${creator.slug}/about/main-info`)}
          >
            <Avatar src={creator?.pic} size="sm" isBorderless />
            <div className={classes['single-blog__author--info']}>
              <h5 className={`${classes['u-body2']} ${classes['u-semiBold']} `}>
                {formatNames(creator?.first_name + ' ' + creator?.last_name)}
              </h5>
              <div className={classes['card__text']}>
                {moment(date_created).format(dateFormatUrl)}
              </div>
            </div>
          </div>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
            iconPosition="left"
            variant="outline"
            icon={!copied ? copyIcon : checkIcon}
          >
            {!copied ? 'Copy Blog Link' : 'Copied'}
          </Button>
        </div>
      </div>
      <div className={classes['wrapper']}>
        <div className={classes['blogs-wrapper']}>
          <h4 className={classes['u-bold']}>From The Blog</h4>
          <div className={classes['cards']}>
            {data?.suggestions?.map((blog) => (
              <BlogCard
                key={blog._id}
                blog={blog}
                onClick={() => navigate(`/blog/${blog.slug}`)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
