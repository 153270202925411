import { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Actions from 'routes/dashboard/profile/components/Actions';
import { userContext } from 'context/userContext';
import Error from 'components/error/Error';
import Loader from 'components/loader/Loader';
import NoResults from 'components/noResults/NoResults';
import { IOption } from 'components/input/InputChoice';
import Tooltip from 'components/tooltip/Tooltip';
import Rating from 'components/rating/Rating';
import Table from 'components/table/Table';
import { AudienceCols } from 'components/table/AudienceCols';
import InfoBox from 'components/info-box/InfoBox';
import {
  ICourseData,
  IFilterData,
  IFilterOptions,
  IReportData
} from 'query/course-module/dto';
import {
  contentCreatorReportQuery,
  creatorCoursesDataQuery
} from 'query/course-module/queries';
import { COURSE_STATUS } from 'utils/constants';
import classes from './CreatorReport.module.scss';

const coursesFilter: IFilterData = {
  filterName: 'Course',
  filterOptions: []
};

const CreatorReport = () => {
  const { search: urlParams } = useLocation();
  const { userData } = useContext(userContext);
  const {
    startDate: startDateParam,
    endDate: endDateParam,
    course
  } = queryString.parse(urlParams);

  const [coursesValue, setCoursesValue] = useState<IOption[]>(
    coursesFilter.filterOptions.filter((option: IFilterOptions) => {
      return course?.includes(option.value as string);
    })
  );
  const [dateRange, setDateRange] = useState<any[]>([
    startDateParam ? new Date(startDateParam as string) : '',
    endDateParam ? new Date(endDateParam as string) : ''
  ]);
  const [startDateRange, endDateRange] = dateRange;

  const queryParams = `?${
    startDateRange && endDateRange
      ? `&buy_tx_confirmed_at[start]=${new Date(
          startDateRange
        ).getTime()}&buy_tx_confirmed_at[end]=${new Date(
          endDateRange
        ).getTime()}`
      : ''
  }${!!course ? `&course_id=${course}` : ''}`;

  const {
    isLoading: reportLoading,
    error: reportError,
    data: reportData
  } = useQuery<boolean, Error, IReportData>(
    contentCreatorReportQuery(queryParams)
  );

  const audienceCols = useMemo(() => AudienceCols(), [reportData]);

  const coursesQueryParams = `?&status=${COURSE_STATUS.PUBLISHED},${COURSE_STATUS.PUBLISHING}
  &creator_id=${userData._id}`;

  useQuery<boolean, Error, ICourseData>({
    ...creatorCoursesDataQuery(coursesQueryParams),
    onSuccess: (courses) => {
      coursesFilter.filterOptions = courses.result.map((category) => ({
        label:
          category.name.length > 25
            ? `${category.name.substring(0, 25)}...`
            : category.name,
        value: category._id
      }));
      setCoursesValue(
        coursesFilter.filterOptions.filter(
          (option: IFilterOptions) => course?.includes(option.value as string)
        )
      );
    }
  });

  const hasData = !!reportData && !!reportData?.result?.length;

  const filterHandler = (value: IOption[]) => {
    setCoursesValue(value);
  };

  const renderStat = useCallback(
    (
      stat?: number,
      toFixed?: number,
      loaderSize?: 'sm' | 'lg',
      preText?: string,
      postText?: string
    ) => {
      const statString = stat?.toString();
      if (reportLoading)
        return <Loader size={loaderSize} className={classes['loader']} />;
      if (reportError) return <Error error={reportError} />;
      if (!statString) return ' - ';
      return ` ${preText || ''}${(+statString).toFixed(toFixed || 0)}${
        postText || ''
      }`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportLoading, reportError, reportData]
  );

  const prepStatsData: any = useMemo(
    () => {
      const conversionRate =
        !!reportData?.result?.[0]?.total_sold &&
        !!reportData?.result?.[0]?.course_visits
          ? ((reportData?.result?.[0]?.total_sold || 0) /
              (reportData?.result?.[0]?.course_visits || 1)) *
            100
          : undefined;

      return {
        total_revenue: {
          title: 'Total revenue',
          value: renderStat(
            reportData?.result?.[0]?.total_revenue || 0,
            2,
            'sm',
            '$'
          )
        },
        creator_revenue: {
          title: 'Creator revenue',
          value: renderStat(
            reportData?.result?.[0]?.creator_revenue || 0,
            2,
            'sm',
            '$'
          )
        },
        platform_fees: {
          title: 'Platform fees',
          value: renderStat(
            reportData?.result?.[0]?.platform_fee || 0,
            2,
            'sm',
            '$'
          )
        },
        total_sold: {
          title: 'Total courses sold',
          value: renderStat(reportData?.result?.[0]?.total_sold || 0, 0, 'sm')
        },
        students_enrolled: {
          title: 'Students enrolled',
          value: renderStat(
            reportData?.result?.[0]?.enrolled_students || 0,
            0,
            'sm'
          )
        },
        course_comments: {
          title: (
            <>
              Reviews & Ratings{' '}
              <Tooltip
                text={`Based on ${renderStat(
                  reportData?.result?.[0]?.course_comments || 0,
                  0,
                  'sm'
                )} reviews`}
                id="reviews-tooltip"
              >
                <InfoIcon width={16} height={16} />
              </Tooltip>
            </>
          ),
          value: (
            <>
              <Rating
                rating={reportData?.result?.[0]?.average_rating || 0}
                totalGivenReviews={
                  reportData?.result?.[0]?.course_comments || 0
                }
                readOnly
              />
            </>
          )
        },
        course_visits: {
          title: (
            <>
              Course visits{' '}
              <Tooltip
                text="Repesents the number of people visiting the course landing page."
                id="course-visits-tooltip"
              >
                <InfoIcon width={16} height={16} />
              </Tooltip>
            </>
          ),
          value: renderStat(
            reportData?.result?.[0]?.course_visits || 0,
            0,
            'sm'
          )
        },
        coversion_rate: {
          title: (
            <>
              Conversion rate{' '}
              <Tooltip
                text="Represents the percentage of people who visit the course landing page and enroll in the course."
                id="conversion-rate-tooltip"
              >
                <InfoIcon width={16} height={16} />
              </Tooltip>
            </>
          ),
          value: renderStat(conversionRate, 2, 'sm', '', '%')
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportData]
  );

  const renderStatistics = (start: number, end: number) => {
    const values = Object.keys(prepStatsData)
      .slice(start, end)
      .map((stat: any) => {
        return (
          <div key={stat} className={classes['creator-report__card']}>
            <h5>{prepStatsData[stat].title}</h5>
            <h2 className={classes['creator-report__card__value']}>
              {prepStatsData[stat].value}
            </h2>
          </div>
        );
      });
    return values;
  };

  return (
    <div className={classes['creator-report']}>
      <div className={classes['creator-report__table-wrapper']}>
        <div className={classes['creator-report__actions-wrapper']}>
          <Actions
            hasFilter
            showFilterMenu
            hideFilterButton
            passFilterValueUp={filterHandler}
            filterStartDateRange={startDateRange}
            filterEndDateRange={endDateRange}
            setDateRange={setDateRange}
            filters={[
              {
                ...coursesFilter,
                filterValue: coursesValue
              }
            ]}
          />
        </div>
        {(hasData || reportLoading) && (
          <div className={classes['creator-report__cards-wrapper']}>
            <h5
              className={`${classes['u-text--content']} ${classes['u-bold']}`}
            >
              Courses
            </h5>
            <div className={classes['creator-report__cards-container']}>
              {renderStatistics(0, 4)}
            </div>
            <h5
              className={`${classes['u-text--content']} ${classes['u-bold']}`}
            >
              Students
            </h5>
            <div className={classes['creator-report__cards-container']}>
              {renderStatistics(4, 10)}
            </div>
            <div className={classes['creator-report__audience']}>
              <h5
                className={`${classes['u-text--content']} ${classes['u-bold']} ${classes['u-m32-bot']}`}
              >
                Audience Report
              </h5>
              {!!reportData?.result?.[0]?.audience_report?.length && (
                <>
                  <Table
                    columns={audienceCols}
                    data={reportData?.result?.[0]?.audience_report || []}
                  />
                  <InfoBox
                    title="Real time data"
                    msg="The data presented in this table is not in real time and may be subject to delays."
                    type="info"
                  />
                </>
              )}
              {!reportData?.result?.[0]?.audience_report?.length && (
                <p> No audience data, yet.</p>
              )}
            </div>
          </div>
        )}
        {reportError && <Error error={reportError} />}
        {!hasData && !reportLoading && (
          <div className={classes['creator-report__no-results']}>
            <NoResults />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreatorReport;
