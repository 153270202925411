import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import ActivationNftImage from 'assets/images/activation-nft-watermark.png';
import ThumbnailPlaceholder from 'assets/images/thumbnail-placeholder.png';
import { popupContext } from 'context/popupContext';
import Summary from './Summary';
import GenericPopup from 'components/popup/GenericPopup';
import FormSteps from 'components/forms/formSteps/FormSteps';
import { CircularProggress } from 'components/progress/CircularProgress';
import { IOption } from 'components/input/InputChoice';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import InfoBox from '../../../../components/info-box/InfoBox';
import {
  createCourseMutation,
  editCourseMutation
} from 'query/course-module/mutations';
import { CourseSchema, ICourseFormFields } from 'utils/yupSchemas';
import { PublishedChangesMsg } from '../single/components/PublishedChangesMsg';
import NavIsland from './NavIsland';
import { COURSE_CHANGES, ICourse } from 'query/course-module/dto';
import { COURSE_REVIEW_STATUS, COURSE_STATUS } from 'utils/constants';
import { getBase64FromUrl } from 'utils/processing';
import { windowContext } from 'context/windowsContext';
import classes from './Form.module.scss';

export interface IFormik {
  errors: FormikErrors<ICourseFormFields>;
  touched: FormikTouched<ICourseFormFields>;
  values: ICourseFormFields;
  setFieldValue: (
    fieldName: string,
    value: string | boolean | IOption[] | null
  ) => void;
  setFieldError: (fieldName: string, value: string) => void;
  setFieldTouched: (fieldName: string, value: boolean) => void;
  submitForm: () => void;
  handleBlur: (e: React.FocusEvent<HTMLElement>) => void;
  isValid: boolean;
}

interface IRenderStep extends IFormik {
  step: number;
}

export interface ICourseForm {
  steps: string[];
  finishedSteps: number;
  setCurrentStep: (step: number) => void;
  currentStep: number;
  setStep: (step: number) => void;
  defaultFormData: ICourseFormFields;
  isEdit?: boolean;
  course?: ICourse;
}

const CourseForm = ({
  steps,
  finishedSteps,
  setCurrentStep,
  currentStep,
  setStep,
  defaultFormData,
  isEdit,
  course
}: ICourseForm) => {
  const { setPopup } = useContext(popupContext);
  const { appWrapperElement } = useContext(windowContext);
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const isDraftOfPublished = changes === COURSE_CHANGES.ONLY_CHANGES;
  const { _id: courseId, not_finished, slug } = course || {};
  const navigate = useNavigate();
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  const setErrorPopup = (msg: string) =>
    setPopup(
      <GenericPopup
        type="error"
        msg={msg}
        buttonName="Close"
        buttonVariant="neutral"
        isClosable={false}
      />
    );

  // Create course mutation
  const { isLoading: isLoadingCreate, mutate: handleCreate } = useMutation({
    ...createCourseMutation(),
    onError: (err: Error) => setErrorPopup(err.message)
  });

  // Create course draft mutation
  const { isLoading: isLoadingDraft, mutate: handleDraft } = useMutation({
    ...createCourseMutation(),
    onError: (err: Error) => setErrorPopup(err.message)
  });

  // Edit course mutation
  const { isLoading: isLoadingEdit, mutate: handleEdit } = useMutation({
    ...editCourseMutation(courseId as string),
    onError: (err: Error) => setErrorPopup(err.message)
  });

  // Edit course draft mutation
  const { isLoading: isLoadingEditDraft, mutate: handleEditDraft } =
    useMutation({
      ...editCourseMutation(courseId as string),
      onError: (err: Error) => setErrorPopup(err.message)
    });

  const fieldsByStep: { [key: number]: string[] } = {
    1: [
      'name',
      'category',
      'subcategory',
      'short_description',
      'description',
      'difficulty_level',
      'requirements',
      'time_to_complete',
      'agenda',
      'skills'
    ],
    2: ['thumbnail', 'activation_nft_image', 'video_preview', 'hover_play'],
    3: ['expiration', 'currency', 'price'],
    4: ['cpe', 'template'],
    5: []
  };

  const handleStepClick = (
    step: number,
    fields: string[],
    setFieldTouched: (fieldName: string, value: boolean) => void
  ) => {
    if (step > 0) fields.forEach((f: string) => setFieldTouched(f, true));
    setStep(step <= 0 ? 0 : step);
  };

  const renderDraftBtn = (values: ICourseFormFields, resetForm: () => void) => {
    if ((isEdit && not_finished) || !isEdit)
      return (
        <InfoBox
          className={classes['info-box']}
          type="info"
          msg="Save as draft and continue the course creation later."
          button={{
            text: 'Save as Draft',
            icon: Check,
            onClick: () => {
              if (isEdit) {
                handleEditDraft(
                  {
                    data: { ...values, template_id: values.template_id },
                    id: courseId as string
                  },
                  {
                    onSuccess: (data) => {
                      setPopup(
                        <GenericPopup
                          msg="You have successfully updated a draft course."
                          redirectPath={`/courses/${data.slug}?&changes=${COURSE_CHANGES.NO_CHANGES}`}
                          buttonName="Go to Course"
                          buttonVariant="contrast"
                          isClosable={false}
                        />
                      );
                    }
                  }
                );
              } else {
                handleDraft(
                  {
                    ...values,
                    not_finished: true
                  },
                  {
                    onSuccess: (data) => {
                      setPopup(
                        <GenericPopup
                          msg="You have successfully updated a draft course."
                          redirectPath={`/courses/${data.slug}?&changes=${COURSE_CHANGES.NO_CHANGES}`}
                          buttonName="Go to Course"
                          buttonVariant="contrast"
                          isClosable={false}
                        />
                      );
                    }
                  }
                );
              }
              resetForm();
            },
            isLoading: isEdit ? isLoadingEditDraft : isLoadingDraft,
            isDisabled: !values.name,
            tooltip: !values.name
              ? 'Please enter a course name before saving the course as a draft.'
              : ''
          }}
        />
      );
    return <></>;
  };

  const renderStep = ({
    step,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleBlur
  }: IRenderStep) => {
    if (step === 0) {
      const getBase64Image = async (src: string) => {
        await getBase64FromUrl(src).then((result: any) => {
          setFieldValue('activation_nft_image', result);
        });
      };

      if (!values.activation_nft_image) {
        getBase64Image(ActivationNftImage);
      }

      const thumbnailPlaceholderBase64 = async (src: string) => {
        await getBase64FromUrl(src).then((result: any) => {
          setFieldValue('thumbnail', result);
        });
      };

      // if (!values.thumbnail) {
      //   thumbnailPlaceholderBase64(ThumbnailPlaceholder);
      // }

      return (
        <Step1
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
        />
      );
    }
    if (step === 1) {
      return (
        <Step2
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
        />
      );
    }

    if (step === 2) {
      return (
        <Step3
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
        />
      );
    }

    if (step === 3) {
      return (
        <Step4
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          isEdit={isEdit}
        />
      );
    }

    return <Summary data={values} errors={errors} setStep={setStep} />;
  };

  useEffect(() => {
    appWrapperElement?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <Formik
      initialValues={defaultFormData}
      validationSchema={CourseSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (
          isLoadingCreate ||
          isLoadingDraft ||
          isLoadingEditDraft ||
          isLoadingEdit
        )
          return;
        if (isEdit) {
          if (not_finished) {
            handleCreate(
              {
                ...values,
                not_finished: false,
                draft_id: courseId as string
              },
              {
                onSuccess: (data) =>
                  navigate(`/courses/${data.slug}?changes=0`, {
                    state: { showContentPopup: true }
                  })
              }
            );
          } else {
            handleEdit(
              { data: values, id: courseId as string },
              {
                onSuccess: (data) => {
                  setPopup(
                    <GenericPopup
                      msg={
                        course?.status === COURSE_STATUS.PUBLISHED
                          ? `You have edited a ${
                              course?.is_draft_copy
                                ? 'draft of this published'
                                : 'published'
                            } course. 
                            When ready, please submit the edited course for approval. 
                            If the changes are approved by the platform, your published course will be updated.`
                          : 'You have successfully updated a course.'
                      }
                      redirectPath={
                        // On editing a published course, navigate to the draft-of-published course
                        course?.status === COURSE_STATUS.PUBLISHED
                          ? `/courses/${data.slug}?&changes=${COURSE_CHANGES.ONLY_CHANGES}`
                          : `/courses/${
                              !!data.slug ? data.slug : data._id
                            }?&changes=${changes}`
                      }
                      buttonName="Go to Course"
                      buttonVariant="contrast"
                      isClosable={false}
                    />
                  );
                  setStep(0);
                }
              }
            );
          }
        } else {
          handleCreate(
            { ...values, thumbnail: '' },
            {
              onSuccess: (data) =>
                navigate(`/courses/${data.slug}/videos?changes=0`, {
                  state: { showContentPopup: true }
                })
            }
          );
        }
        setSubmitting(false);
      }}
      validateOnBlur
      validateOnMount
    >
      {(props) => (
        <Form className={classes['form-wrapper']}>
          {!isLgMobile && (
            <FormSteps
              steps={steps}
              currentStep={currentStep}
              finishedSteps={finishedSteps}
              setCurrentStep={(step: number) => setCurrentStep(step)}
              errors={props.errors}
              fieldsByStep={fieldsByStep}
              onStepClick={(i) => {
                handleStepClick(i, fieldsByStep[i], props.setFieldTouched);
              }}
            />
          )}
          <div className={classes['form']}>
            {course?.status === COURSE_STATUS.PUBLISHED && (
              <PublishedChangesMsg
                courseSlug={slug as string}
                msg={
                  isDraftOfPublished
                    ? course.review_status === COURSE_REVIEW_STATUS.REJECTED
                      ? 'The changes for this course were rejected. Please revisit the submitted changes and make sure they conform to the guidelines. Once ready, you can apply for approval again.'
                      : 'Course has unpublished changes.'
                    : 'Editing this published course will create a new copy of it in your drafts. This new copy has to go the through approval process before the changes are reflected in your published course.'
                }
              />
            )}
            {isLgMobile ? (
              <div className={classes['progress-bar-wrapper']}>
                <CircularProggress
                  steps={steps.length}
                  step={currentStep + 1}
                  percentage={((currentStep + 1) / steps.length) * 100}
                />
                <div className={classes['progress-bar-wrapper__labels']}>
                  <h5
                    className={`${classes['u-semiBold']} ${classes['u-body1']}`}
                  >
                    {steps[currentStep]}
                  </h5>
                  {currentStep < steps.length - 1 && (
                    <h5
                      className={`${classes['u-text--content']} ${classes['u-body3']}`}
                    >
                      {`Next: ${steps[currentStep + 1]}`}
                    </h5>
                  )}
                </div>
              </div>
            ) : (
              <h4 className={classes['u-light']}>{steps[currentStep]}</h4>
            )}
            {renderStep({
              step: currentStep,
              ...props
            })}
            <>{renderDraftBtn(props.values, props.resetForm)}</>
            <NavIsland
              course={course}
              isEdit={!!isEdit}
              steps={steps.length}
              isValid={props.isValid}
              currentStep={currentStep}
              setStep={setStep}
              onNextStepClick={() =>
                handleStepClick(
                  currentStep + 1,
                  fieldsByStep[currentStep + 1],
                  props.setFieldTouched
                )
              }
              submitForm={props.submitForm}
              isSubmitLoading={
                isEdit
                  ? not_finished
                    ? isLoadingCreate
                    : isLoadingEdit
                  : isLoadingCreate
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CourseForm;
