import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Actions from 'routes/dashboard/profile/components/Actions';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import Pagination from 'components/pagination/Pagination';
import NoResults from 'components/noResults/NoResults';
import BlogCardRow from 'components/card/BlogCardRow';
import Loader from 'components/loader/Loader';
import { IBlogData, IBlogTag } from 'query/course-module/dto';
import { blogTags, blogsDataQuery } from 'query/course-module/queries';
import classes from './Blog.module.scss';

const Blog = () => {
  const { search: urlParams } = useLocation();
  const [selectedTag, setSelectedTag] = useState('');
  const { page: pageNumber, search } = queryString.parse(urlParams);

  const pageSize: number = 7;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1 ? `&search[title]=${searchValue}` : ''
  }${!!selectedTag ? `&tags=${selectedTag}` : ''}`;

  const { data: blogsData, isLoading } = useQuery<
    boolean,
    Error,
    { result: IBlogData[]; total_results: number }
  >(blogsDataQuery(queryParams));

  const { data: tags } = useQuery<boolean, Error, IBlogTag[]>(blogTags());

  const crumbs = [
    { name: 'Home', link: '/' },
    { name: 'Blog', link: '/blog' }
  ];

  return (
    <div className={classes['blog-wrapper']}>
      <CustomBreadcrumbs crumbs={crumbs} />
      <div className={classes['header']}>
        <h5
          className={`${classes['link-contrast']} ${classes['u-body1']} ${classes['u-semiBold']}`}
        >
          Our blog
        </h5>
        <h2 className={`${classes['u-semiBold']} ${classes['title']}`}>
          Resources and insights
        </h2>
        <h5 className={`${classes['u-text--content']}`}>
          The latest news, technologies and resources
        </h5>
      </div>
      <div className={classes['content']}>
        <div className={classes['content__search']}>
          <Actions
            isInverted
            hasSearch
            passSearchValueUp={setSearchValue}
            searchValue={searchValue}
            searchPlaceholder="Search"
            setPage={setPage}
            isDebounced
          />
          <h5
            className={`${classes['link-contrast']} ${classes['u-body2']} ${classes['u-semiBold']}`}
          >
            Blog tags
          </h5>
          <div className={classes['content__search__tags']}>
            {tags?.map((tag, index) => (
              <Fragment key={tag.slug}>
                {index === 0 && (
                  <h5
                    onClick={() => setSelectedTag('')}
                    className={`${classes['u-body1']} ${
                      classes['u-text--content']
                    } ${!selectedTag ? classes['selected'] : ''}`}
                  >
                    All
                  </h5>
                )}
                <h5
                  onClick={() => setSelectedTag(tag.slug)}
                  className={`${classes['u-body1']} ${
                    classes['u-text--content']
                  } ${tag.slug === selectedTag ? classes['selected'] : ''}`}
                >
                  {tag.name}
                </h5>
              </Fragment>
            ))}
          </div>
        </div>
        <div className={classes['content__blog']}>
          {!!blogsData?.result?.length ? (
            <>
              <BlogCardRow
                queryData={{
                  isLoading: false,
                  error: null,
                  data: blogsData?.result
                }}
                isBlogPage
                page={page}
              />
              <Pagination
                currentPage={page}
                siblingCount={1}
                pageSize={pageSize}
                onPageChange={(page) => setPage(page)}
                totalCount={(blogsData?.total_results as number) || 0}
              />
            </>
          ) : isLoading ? (
            <Loader />
          ) : (
            <NoResults />
          )}
        </div>
      </div>
    </div>
  );
};
export default Blog;
