import { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import Player from 'components/player/Player';
import SEO from 'components/SEO/SEO';
import VideoList from '../components/VideoList';
import { getFreeCourseVideoData } from 'query/course-module/queries';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  ICourse,
  ISingleCourseVideo,
  IVideo
} from 'query/course-module/dto';
import { colors } from 'utils/styles';
import { formatNames } from 'utils/format';
import classes from '../SingleCourse.module.scss';

interface IVideoViewProps {
  courseData: ICourse;
  view: 'video' | 'description' | 'video-and-description' | 'video-list';
  videosListData?: IVideo[];
}

const VideoViewFreeCourse = ({
  courseData,
  view,
  videosListData
}: IVideoViewProps) => {
  const { videoId, slug } = useParams();

  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const navigate = useNavigate();
  const { isTheatreMode, setIsTheatreMode } = useContext(appContext);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;
  const isVideoPage = !!videoId;

  const { _id, course_relationship, purchased } = courseData;

  // Get video
  const {
    isLoading: videoIsLoading,
    error: videoError,
    data: videoData
  } = useQuery<boolean, Error, ISingleCourseVideo>({
    ...getFreeCourseVideoData(_id as string, videoId as string),
    enabled: isVideoPage && !!_id,
    onError: (error) => {
      navigate(`/courses/${slug}/videos?changes=${COURSE_CHANGES.NO_CHANGES}`);
    }
  });

  // Remove theatre mode on mobile devices
  useEffect(() => {
    if (isLgMobile && isTheatreMode) {
      setIsTheatreMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLgMobile, isTheatreMode]);

  if (videoIsLoading) return <Loader />;
  if (videoError) return <Error error={videoError} />;

  const {
    description: videoDescription,
    title: videoTitle,
    file_count
  } = videoData || {};

  return (
    <div
      className={`
        ${classes['main']}  
        ${isTheatreMode ? classes['main--theatre-mode'] : ''}
      `}
    >
      <SEO
        type="product"
        title={videoData.title}
        desc={videoData.description}
        image={videoData.thumbnail}
        creatorName={formatNames(
          courseData.creator.first_name + ' ' + courseData.creator.last_name
        )}
      />
      {(view === 'video' || view === 'video-and-description') && (
        <div
          className={`${classes['main__video']}   
          ${isTheatreMode ? classes['main__video--theatre-mode'] : ''}`}
        >
          <Player
            src={videoData?.path}
            hasControls
            checkpoint={
              +(isVideoPage && purchased?.videos_checkpoint[videoId]) || 0
            }
            nextVideoId={videoData.next_video_id}
            videoData={videoData}
          />
        </div>
      )}
      {(view === 'description' || view === 'video-and-description') && (
        <div className={classes['main__content']}>
          <h3 className={classes['u-semiBold']}>
            {videoTitle || '(Untitled Video)'}
          </h3>
          <div className={classes['details']}>
            <div className={classes['details__title']}>Video Description</div>
            <div className={classes['details__content']}>
              <TextWithCharLimit text={videoDescription || ''} limit={300} />
            </div>
          </div>
          {!!file_count && (
            <Link
              className={classes['u-medium']}
              style={{ color: colors.purple50 }}
              to={`/courses/${slug}/${videoId}/documents?changes=${changes}`}
              state={{ autoFocus: true }}
            >
              {file_count} Document{file_count > 1 ? 's' : ''} attached
            </Link>
          )}
        </div>
      )}
      {view === 'video-list' && !!videosListData && (
        <VideoList
          courseProps={{
            course: courseData,
            isAdminPage: false,
            isPublic: false,
            isCourseActivated:
              course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
              purchased?.active,
            isVideoPage: !!videoId
          }}
          videos={videosListData}
        />
      )}
    </div>
  );
};

export default VideoViewFreeCourse;
